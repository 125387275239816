.header {
  margin: 0;
  width: 100vw;
  height: 100%;
  z-index: 0;
  /* overflow-y: scroll; */
}
.header-image {
  display: block;
  max-width: 100%;
  height: auto;
  margin-bottom: 20px; /* Adjust as needed */
}

.header h1 {
  position: absolute;
  justify-content: center;
  top: 50%;
  left: 25%;
  text-align: center;
  font-family: var(--font-main);
  font-size: 6rem;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 0.3rem;
  -webkit-text-stroke-color: #fff;
}
@media screen and (max-width: 1200px) {
  .header h1 {
    top: 50%;
    left: 20%;
    font-size: 4.5rem;
    -webkit-text-stroke-width: 0.2rem;
  }
}
@media screen and (max-width: 820px) {
  .header h1 {
    top: 40%;
    left: 15%;
    font-size: 4rem;
  }
}

@media screen and (max-width: 450px) {
  .header h1 {
    top: 40%;
    left: 15%;
    font-size: 2rem;
    -webkit-text-stroke-width: 0.1rem;
  }
  .header {
    height: 60%;
  }
}
