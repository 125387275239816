.features {
  background-color: #fff4f4;
  padding: 4rem 4rem 2rem;
  border-radius: 8% 0 0 0;
  margin-top: 6rem;
}
.features h1 {
  font-family: var(--font-main);
  font-size: 0.8rem;
}
.features-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.features-quote {
  color: #1a1a1a;
  font-family: var(--font-main);
  font-size: 2.5rem;
  line-height: 4rem;
  max-width: 25rem;
}
.features-items {
  display: flex;
  flex-direction: column;
}
.features-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 4rem;
  width: 20rem;
  margin-bottom: 1.5rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
}
.features-item:hover {
  transform: translateY(-10%);
  transition: transform 300ms ease-in-out;
}
.features-item h2 {
  font-family: var(--font-main);
  font-size: 0.8rem;
}
.features-item-icon {
  padding: 0.2rem 0.3rem;
  background-color: #cc5959;
  border-radius: 0.3rem;
  align-items: center;
  margin-right: 1rem;
}
.differ {
  margin-left: 14rem;
}

.features-action {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1rem;
}

.features-action p {
  color: #5f5f5f;
  font-family: var(--font-sub);
  font-weight: 600;
  font-size: 1rem;
}

.features-action-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.features-action-button p {
  color: #cc5959;
  padding-right: 1rem;
}
.features-action-button:hover p {
  transform: translateX(-5%);
  transition: transform 300ms ease-in-out;
}

.features-action-button:hover .features-arrow {
  transform: translateX(5%);
  transition: transform 350ms ease-in-out;
}

@media screen and (max-width: 850px) {
  .features-content {
    padding-top: 1rem;
    flex-direction: column;
    align-items: flex-start;
  }
  .features-quote {
    font-size: 3rem;
    line-height: 6rem;
    max-width: 40rem;
    padding: 2rem 0;
  }
  .features-action {
    padding-top: 2rem;
    flex-direction: column;
    align-items: flex-start;
  }
  .differ {
    margin-left: 18rem;
  }
  .features-action-button {
    margin-top: 0.5rem;
  }
}
@media screen and (max-width: 550px) {
  .features {
    background-color: #fff4f4;
    padding: 4rem 2rem 2rem;
    border-radius: 8% 0 0 0;
    margin-top: 6rem;
  }
  .features-quote {
    font-size: 2rem;
    line-height: 4rem;
    max-width: 40rem;
    padding: 2rem 0;
  }
  .features-action {
    padding-top: 2rem;
    flex-direction: column;
    align-items: flex-start;
  }
  .differ {
    margin-left: 0;
  }
}
@media screen and (max-width: 420px) {
  .features-quote {
    font-size: 2rem;
    line-height: 4rem;
    max-width: 30rem;
    padding: 2rem 0;
  }
  .features-item {
    width: 20rem;
  }
}
@media screen and (max-width: 380px) {
  .features-item {
    width: 18rem;
  }
}
