.home {
  margin: 0;
  overflow: hidden;
}
.home-header {
  margin: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.home-header-video {
  max-width: 100%;
  height: auto;
  z-index: -1;
  overflow: hidden;
}
.home-video-container {
  width: 100%;
  height: 100%;
}
.bg-video {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.home-slider .slick-slide {
  padding: 0;
}

.home-slider .slick-dots-container {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
.home-slider .slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  bottom: 8rem;
  left: 38rem;
}

.home-slider .slick-dots li {
  margin: 0 15px;
}

.home-slider .slick-dots li button {
  display: block;
  width: 40px;
  height: 5px;
  border-radius: 0%;
  border: none;
  background-color: #888;
  cursor: pointer;
  padding: 0;
}
.home-slider .slick-dots li button::before {
  content: none;
}

.home-slider .slick-dots li.slick-active button {
  background-color: #fff;
}

.home-header h1 {
  position: absolute;
  color: #fff;
  font-size: 6rem;
  font-family: var(--font-main);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  top: 15rem;
  padding: 0 5rem;
}
.home-header p {
  position: absolute;
  color: #fff;
  font-size: 1rem;
  font-family: var(--font-main);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  top: 38rem;
  padding: 0 10rem 0 5rem;
}


@media screen and (max-width: 1300px) {
  .home-slider .slick-dots,
  .home-slider .slick-dots-container {
    display: none;
  }
  .home-header h1 {
    font-size: 6rem;
    line-height: small;
    top: 8rem;
  }
}

@media screen and (max-width: 1200px) {
  .home-slider .slick-dots,
  .home-slider .slick-dots-container {
    display: none;
  }
  .home-header h1 {
    font-size: 5rem;
    line-height: small;
    top: 6rem;
  }

  .home-header p {
    font-size: 1rem;
    line-height: small;
    font-weight: 300;
    top: 30rem;
  }
}

@media screen and (max-width: 992px) {
  .home-header h1 {
    font-size: 3rem;
    line-height: small;
    top: 6rem;
    padding: 0 2rem;
  }

  .home-header p {
    font-size: 0.8rem;
    line-height: small;
    font-weight: 300;
    top: 18rem;
    padding: 0 2rem;
  }
}

@media screen and (max-width: 780px) {
  .home-header .slick-slide {
    padding: 0 !important;
  }
  .home-header h1 {
    font-size: 3rem;
    line-height: small;
    top: 6rem;
    padding: 0 2rem;
  }

  .home-header p {
    font-size: 0.8rem;
    line-height: small;
    font-weight: 300;
    top: 18rem;
    padding: 0 2rem;
  }
}

@media screen and (max-width: 500px) {
  .home-header h1 {
    font-size: 1.4rem;
    top: 5rem;
    padding: 0 1.5rem;
  }

  .home-header p {
    font-size: 0.5rem;
    top: 10rem;
    padding: 0 1.5rem;
  }
}
