.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  padding: 10px;
  position: relative;
  z-index: 9999;
}

.navbar.scrolled {
  background-color: rgba(26, 26, 26, 0.8);
  backdrop-filter: blur(10px);
}

.navbar.scrolled .navbar-link a,
.navbar.scrolled .icon {
  color: #f0f0f0;
}

.navbar-logo img {
  width: 100px;
  height: auto;
  cursor: pointer;
}

.navbar-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 100%;
  right: 0;
  height: 0;
  width: 100%;
  overflow: hidden;
  transition: height 0.3s ease;
  z-index: 9999;
}

.navbar-menu.active {
  height: auto;
}

.navbar-menu.active {
  background-color: rgba(26, 26, 26, 0.8);
  backdrop-filter: blur(10px);
}

.navbar-links,
.navbar-icons {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  z-index: 0;
  align-items: center;
}

.navbar-icons {
  flex-direction: row;
  margin: 10px 10px;
}

.navbar-link {
  margin-bottom: 10px;
}

.navbar-icon {
  margin-right: 10px;
}

.navbar-link a {
  text-decoration: none;
  color: #9a9a9a;
  font-family: var(--font-main);
  font-size: 0.75rem;
}

.navbar-link a:hover {
  color: #fff;
}

.navbar-link.active a {
  color: #fff;
}

.navbar-toggle {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 30px;
  height: 25px;
}

.toggle-icon {
  width: 25px;
  height: 3px;
  background-color: #9a9a9a;
  transition: transform 0.3s ease;
}

.navbar-toggle.active .toggle-icon:nth-child(1) {
  transform: translateY(11px) rotate(45deg);
}

.navbar-toggle.active .toggle-icon:nth-child(2) {
  opacity: 0;
}

.navbar-toggle.active .toggle-icon:nth-child(3) {
  transform: translateY(-11px) rotate(-45deg);
}

.navbar.active {
  background-color: rgba(26, 26, 26, 0.8);
  backdrop-filter: blur(10px);
}

.icon {
  color: #9a9a9a;
}

.icon:hover {
  color: #fff;
  cursor: pointer;
}


@media (min-width: 1200px) {
  .navbar {
    padding: 30px 72px;
  }

  .navbar-menu {
    flex-direction: row;
    height: auto;
    align-items: center;
    position: relative;
    top: initial;
    right: initial;
    width: initial;
    background-color: initial;
    padding: initial;
  }

  .navbar-links,
  .navbar-icons {
    flex-direction: row;
  }

  .navbar-link {
    margin-right: 15px;
    margin-bottom: 0;
  }

  .navbar-icons {
    margin-left: 270px;
  }

  .navbar-icon {
    margin-left: 15px;
    margin-top: 0;
  }
  .navbar-toggle {
    display: none;
  }

  .navbar-menu.active {
    background-color: transparent;
  }
}
