.service-slider {
  position: relative;
  width: 100%;
  max-height: 100%;
}
.service-image {
  position: absolute;
  top: 10%;
  right: -10%;
  height: 36rem;
  width: 24rem;
}
.service-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;
  filter: saturate(0);
}

.service-normal {
  position: absolute;
  top: 15%;
  right: -5%;
  height: 36rem;
  width: 24rem;
  background: #cc5959;
  border-radius: 1rem;
}
.service-slider-slides .slick-slide {
  padding: 0 !important;
}
.service-slider-slides {
  position: absolute;
  top: 20%;
  right: 0%;
  height: 36rem;
  width: 24rem;
  padding: 0;
}
.service-slider-image {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  overflow: hidden;
}
.service-slider-image img {
  filter: saturate(0);
}
.service-slider-slides:hover .service-slider-image img {
  filter: saturate(1);
  transition: ease-in-out 100ms;
}
.service-slider-slides .slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  top: -52rem;
  left: 6rem;
}

.service-slider-slides .slick-dots li {
  margin: 0 15px;
}

.service-slider-slides .slick-dots li button {
  display: block;
  width: 40px;
  height: 5px;
  border-radius: 0%;
  border: none;
  background-color: #888;
  cursor: pointer;
  padding: 0;
}
.service-slider-slides .slick-dots li button::before {
  content: none;
}

.service-slider-slides .slick-dots li.slick-active button {
  background-color: #cc5959;
}
.service-slider-slides .custom-next-arrow {
  position: absolute;
  bottom: -6%;
  right: 4%;
}
.service-slider-slides .custom-prev-arrow {
  position: absolute;
  bottom: -6%;
  left: 4%;
}
.service-slider-slides .custom-next-arrow,
.service-slider-slides .custom-prev-arrow {
  z-index: 5;
  cursor: pointer;
}
@media screen and (max-width: 1200px) {
  .service-slider {
    display: none;
  }
}
