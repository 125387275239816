.testimonies {
  margin: 6rem 2rem;
}
.testimonies .slick-dots-testimony {
  display: flex;
}
.testimonies .slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
}

.testimonies .slick-dots li {
  margin: 0 5px;
}

.testimonies .slick-dots li button {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #000000;
  background-color: #fff;
  cursor: pointer;
  padding: 0;
}
.testimonies .slick-dots li button::before {
  content: none;
}

.testimonies .slick-dots li.slick-active button {
  background-color: #000;
}
.testimony {
  display: flex;
  width: 15rem;
  height: auto;
  padding: 4rem 3rem;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 1rem 0;
  position: relative;
  border-radius: 1rem;
}
.testimonies .slick-slide {
  padding: 3rem 8rem 2rem;
}
.quote-icon {
  position: absolute;
  top: -5%;
  left: -2%;
}
.testimony h2 {
  font-size: 1rem;
  font-family: var(--font-sub);
  font-weight: 500;
  line-height: 1.5rem;
  color: #5f5f5f;
}
.testimony h3 {
  font-size: 1rem;
  font-family: var(--font-sub);
  font-weight: 600;
  line-height: 1.5rem;
  color: #cc5959;
  text-align: right;
}
.testimony p {
  font-size: 0.8rem;
  font-family: var(--font-sub);
  font-weight: 400;
  line-height: 1rem;
  color: #1a1a1a;
  font-style: italic;
  text-align: right;
}

@media screen and (max-width: 900px) {
  .testimonies .slick-slide {
    padding: 2rem 6rem 2rem;
  }
}
@media screen and (max-width: 600px) {
  .testimonies .slick-slide {
    padding: 2rem 2rem 2rem;
  }
  .testimony {
    padding: 2.5rem 2rem;
  }
  .testimony h2,
  .testimony h3 {
    font-size: 0.8rem;
    line-height: 1rem;
  }
  .testimony p {
    font-size: 0.6rem;
    line-height: 0.8rem;
  }
  .quote-icon {
    top: -6%;
    left: -4%;
  }
}
