.work {
  display: flex;
  flex-direction: column;
  padding: 2rem 4rem 0rem;
}
.work h2 {
  font-family: var(--font-main);
  font-size: 1rem;
  color: #1a1a1a;
}
.work-container {
  display: flex;
  align-items: center;
  object-fit: contain;
  max-width: 100vw;
}
.work-slider {
  margin: auto;
  padding: 40px;
  width: 95%;
  background-color: transparent;
  align-items: center;
  overflow: hidden;
}
.work-slider::-webkit-scrollbar {
  display: none;
}
.slick-slider {
  place-items: center;
  flex-direction: row;
  max-width: 100vw;
  align-items: center;
}
.work-slide {
  display: flex;
  width: 15rem;
  height: 20rem;
  padding: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.work-image {
  min-width: 100%;
  height: 100%;
  border-radius: 2%;
  object-fit: cover;
}
.work-slider .slick-dots-work {
  display: flex;
}
.work-slider .slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
}

.work-slider .slick-dots li {
  margin: 0 5px;
}

.work-slider .slick-dots li button {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #000000;
  background-color: #fff;
  cursor: pointer;
  padding: 0;
}
.work-slider .slick-dots li button::before {
  content: none;
}

.work-slider .slick-dots li.slick-active button {
  background-color: #000;
}

.work-slider .slick-slide {
  padding: 0.5rem 6rem 2rem;
}

.custom-next-arrow {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateY(50%);
  z-index: 1;
}

.work-slide p {
  margin-top: -2rem;
  text-align: center;
  font-family: var(--font-sub);
  color: #fff;
  font-weight: 400;
  font-size: 1rem;
}

.custom-prev-arrow {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(50%);
  z-index: 1;
}
.custom-next-arrow,
.custom-prev-arrow {
  background-color: transparent;
  border: 2px solid #000000;
  padding: 3.5px 3.5px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

.video-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-container {
  max-width: 100%;
  max-height: 100%;
}

.video-container iframe {
  width: 100%;
  height: 100%;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: transparent;
  border: 1px #fff solid;
  font-size: 18px;
  cursor: pointer;
  border-radius: 50%;
  padding: 2px;
  z-index: 1;
}

@media screen and (max-width: 1200px) {
  .custom-next-arrow,
  .custom-prev-arrow {
    display: none;
  }
  .work-slide {
    height: 15rem;
  }
  .work-slider .slick-slide {
    padding: 0.5rem 3rem 1rem;
  }
}
@media screen and (width: 1024) and (height: 1366px) {
  .custom-next-arrow,
  .custom-prev-arrow {
    display: none;
  }
  .work-slide {
    height: 25rem;
  }
  .work-slider .slick-slide {
    padding: 0.5rem 3rem 1rem;
  }
}
@media screen and (max-width: 850) {
  .work {
    padding: 2rem 2rem 0;
  }
  .work-slide {
    height: 1rem;
  }
}
@media screen and (max-width: 450px) {
  .work {
    padding: 2rem 1.5rem 0;
  }
  .work-slider {
    padding: 20px 10px;
  }
  .work-slide {
    height: 25rem;
  }
  .work-slider .slick-slide {
    padding: 0.5rem 0rem 2rem;
  }
}
@media screen and (min-width: 2000px) {
  .work-slide {
    height: 60rem;
  }
  .work-slider .slick-slide {
    padding: 0.5rem 3rem 1rem;
  }
  .work-slide p {
    margin-top: -5rem;
    font-size: 2rem;
  }
}
