.contact-section {
  display: flex;
  flex-direction: row;
}
.contact-image-container {
  background-image: url(../assets/images/onur-emre-4brjkH_noEs-unsplash-color.png);
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 50rem;
  flex: 0.8;
  padding: 4rem;
}
.contact-image-container h1 {
  font-size: 1rem;
  font-family: var(--font-main);
  font-weight: normal;
  color: #fff;
}

.contact-info {
  font-size: 0.8rem;
  font-family: var(--font-main);
  margin-top: 18rem;
  padding: 0;
  font-weight: normal;
  color: #fff;
}
.contact-info a {
  font-size: 0.8rem;
  font-family: var(--font-main);
  padding-top: 18rem;
  font-weight: normal;
  color: #fff;
}
h3 {
  padding-top: 1rem;
}
.contact-description {
  font-size: 1rem;
  line-height: 2rem;
  font-family: var(--font-sub);
  padding-top: 6rem;
  font-weight: 600;
  color: #fff;
}

.contact-call-to-action {
  display: flex;
  flex-direction: column;
  padding-top: 4rem;
}
.contact-call-to-action p {
  font-size: 1rem;
  font-family: var(--font-sub);
  font-weight: 600;
  color: #fff;
}
.call-to-action {
  display: flex;
  flex-direction: row;
  padding-top: 0.5rem;
  align-items: center;
  cursor: pointer;
}
.call-to-action p {
  font-size: 1rem;
  font-family: var(--font-sub);
  font-weight: 600;
  color: #fff;
  padding-right: 0.8rem;
}
.call-to-action:hover p {
  transform: translateX(5%);
  transition: transform 300ms ease-in-out;
}

.call-to-action:hover .contact-arrow {
  transform: translateX(-5%);
  transition: transform 350ms ease-in-out;
}

.contact-form-container {
  background-color: #fff4f4;
  width: 100%;
  height: 50rem;
  flex: 1;
  padding: 4rem;
  display: flex;
  flex-direction: column;
}
.contact-form-container p {
  font-size: 1rem;
  font-family: var(--font-sub);
  font-weight: 600;
  color: #1a1a1a;
  padding-top: 3rem;
}
.contact-form-container input {
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;
  font-size: 1rem;
  font-family: var(--font-sub);
  font-weight: 500;
  margin-bottom: 2rem;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
}
.contact-form-container textarea {
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;
  font-size: 1rem;
  font-family: var(--font-sub);
  font-weight: 500;
  margin-bottom: 2rem;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
  height: 24rem;
  resize: none;
}
.contact-form-button {
  border-radius: 0.5rem;
  padding: 1rem;
  font-size: 1rem;
  font-family: var(--font-sub);
  font-weight: 600;
  margin-bottom: 2rem;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
  color: #fff;
  background-color: #cc5959;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 8rem;
  justify-content: center;
}
.contact-form-button:hover {
  box-shadow: 0 0 1rem 0 rgba(26, 26, 26, 0.3);
}
.contact-form-button span {
  padding-left: 0.5rem;
}

@media screen and (max-width: 900px) {
  .contact-section {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 500px) {
  .contact-form-container,
  .contact-image-container {
    padding: 4rem 2rem;
  }
}
