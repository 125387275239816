* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-size: 16px;
}
a {
  color: unset;
  text-decoration: none;
}
.navbar {
  z-index: 10;
}
.animation {
  opacity: 0;
  transform: translateY(300px);
  transition: all 0.7s ease-out;
  transition-delay: 0.4s;
  z-index: 1;
}

.slide-in {
  transform: translateY(0) !important;
  opacity: 1 !important;
}