.about-container {
  position: relative;
  border-radius: 6%;
  background: #fff;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1);
  padding: 2.5rem 2rem 4rem;
  max-width: 30rem;
  height: 12rem;
}
.about-container h1 {
  color: #1a1a1a;
  font-size: 0.8rem;
  font-family: var(--font-main);
}
.about-container p {
  color: #5f5f5f;
  font-size: 0.8rem;
  line-height: 1.2rem;
  font-family: var(--font-sub);
  font-weight: 600;
  padding-top: 0.5rem;
}
.about-container .about-container-icon {
  position: absolute;
  top: -10%;
  left: 6%;
  padding: 0.5rem;
  border-radius: 20%;
  background: #ffe5e5;
}
@media screen and (max-width: 500px) {
  .about-container {
    height: 14rem;
  }
}
