.project {
  padding-top: 4rem;
}
.project h1 {
  font-family: var(--font-main);
  font-size: 0.8rem;
  padding: 0 4rem 2rem;
}

.project-slider .slick-slide {
  padding: 0 !important;
}
.project-image {
  width: 30rem;
  height: 20rem;
  overflow: hidden;
}
.project-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.project-image:hover img {
  transform: scale(1.5);
  transition: transform 400ms ease-in-out;
}
@media screen and (max-width: 550px) {
  .project-image {
    width: 20rem;
    height: 10rem;
    overflow: hidden;
  }
  .project h1 {
  padding: 0 2rem 1rem;
}
}
