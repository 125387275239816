.works {
  overflow: hidden;
}
.navbar {
  position: fixed;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

.header {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
