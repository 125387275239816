.team {
  display: flex;
  flex-direction: column;
  padding: 4rem 0 0;
}
.team h1 {
  padding: 0 4rem;
  color: #1a1a1a;
  font-size: 0.8rem;
  font-family: var(--font-main);
}
.team-members {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 2rem 4rem 0;
}
.team-member {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.team-member-container:hover .team-member-icons {
  display: flex;
  flex-direction: row;
  background-color: rgba(0, 0, 0, 0.3);
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  border-radius: 0 4% 0 0;
}
.team-member-icons {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.team-member-container {
  position: relative;
  height: 24rem;
  width: 18rem;
}
.team-member-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0 4% 0 0;
}
.social-icon {
  padding: 0.4rem 0.5rem;
  background-color: #ffe5e5;
  justify-content: center;
  align-items: center;
  border-radius: 20%;
  cursor: pointer;
}

.team-member h1 {
  padding-top: 0.5rem;
  color: #1a1a1a;
  text-align: center;
  font-size: 1rem;
  font-family: var(--font-sub);
  font-weight: 700;
}
.team-member p {
  padding-top: 0.2rem;
  color: #5f5f5f;
  text-align: center;
  font-size: 0.8rem;
  font-family: var(--font-sub);
  font-weight: 600;
  font-style: italic;
}
.team-quote {
  padding: 8rem 0 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1a1a1a;
  text-align: center;
  font-size: 2rem;
  font-family: var(--font-sub);
  font-weight: 600;
}
.team-join {
  display: flex;
  flex-direction: row;
  padding: 4rem;
  background-color: #1a1a1a;
  justify-content: space-between;
  align-items: center;
}
.team-join-text {
  display: flex;
  flex-direction: column;
  padding-right: 5rem;
}
.team-join p {
  color: #adadad;
  text-align: center;
  font-size: 0.8rem;
  font-family: var(--font-sub);
  text-align: left;
  font-weight: 600;
}
.team-join h2 {
  padding: 0.5rem 0 0 0;
  color: #fff;
  text-align: center;
  font-size: 1rem;
  font-family: var(--font-sub);
  text-align: left;
  font-weight: 600;
}
.team-join button {
  display: block;
  text-align: center;
  font-size: 1rem;
  font-family: var(--font-sub);
  font-weight: 600;
  height: 3rem;
  width: 10rem;
  color: #fff;
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 8%;
  cursor: pointer;
}
.team-join button:hover {
  background: #fff;
  border: none;
  color: #1a1a1a;
  transition: background-color 0.3s ease-out;
}
@media screen and (max-width: 1050px) {
  .team-join button {
    width: 12rem;
  }

  .team-members {
    display: flex;
    flex-direction: column;
    padding: 3rem 2rem 0;
  }
  
  .team-member {
    padding-bottom: 3rem;
  }
}
@media screen and (max-width: 850px) {
  .team-join button {
    width: 18rem;
  }
}
@media screen and (max-width: 500px) {
  .team h1 {
    padding: 0 2rem;
  }
  .team-quote {
    padding-top: 3rem;
    font-size: 1.5rem;
  }
  .team-join {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    align-items: flex-start;
  }
  .team-join-text {
    padding: 0 0 1.5rem;
  }
  .team-join button {
    width: 8rem;
  }
  .team-member h1 {
    padding-top: 0.5rem;
  }
}
